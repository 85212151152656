<template>
  <div>
    <b-row class="mt-5">
      <b-col cols="3" v-for="(metric, ix) in clientMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <rm-request-table></rm-request-table>
      </b-col>
    </b-row>
    <!-- <div class="d-flex">
			<rm-new-clients-chart />
			<rm-country-chart class="mx-4" />
			<rm-age-chart />
		</div>
		<b-row class="mt-5">
			<b-col cols="12">
				<rm-clients-table />
			</b-col>
			<b-col cols="4"> </b-col>
			<b-col cols="4"> </b-col>
		</b-row> -->
  </div>
</template>

<script>
import RmMetricCard from "@/components/global/RmMetricCard2.vue";
// import RmInsights from "./components/RmInsights.vue";
// import RmPlotSales from "./components/RmPlotSales.vue";
// import RmRatingCard from "./components/RmRatingsCard.vue";
import RmRequestTable from "./components/RmRequestTable.vue";
export default {
  components: {
    RmMetricCard,
    // RmInsights,
    // RmPlotSales,
    // RmRatingCard,
    RmRequestTable,
  },
  data: () => ({
    metricOptions: ["All Time"],
    clientMetrics: [
      {
        name: "Total Payments",
        amount: "5000",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Payments Default",
        amount: "3000",
        growth: +15,
        icon: "active-clients",
      },
      {
        name: "Pending Payments",
        amount: "1000",
        growth: +100,
        icon: "inactive-clients",
      },
      {
        name: "Pending Withdrawal",
        amount: "1000",
        growth: -22.5,
        icon: "prospective-clients",
      },
    ],
  }),
};
</script>
